import React from 'react';
import { connect } from 'react-redux';
import { getRankings } from '../selectors/selectors';
import TableRow from './TableRow';

class Table extends React.Component {
  render() {
    const format = this.props.leagueFormat.replace(/ /g, '_').toLowerCase();
    const rows = this.props.rankings.map((el, i) => {
      return (
        <TableRow 
          key={i + '-' + el} 
          rank={el.rank} 
          rankAverageRaw={el.rank_average_raw} 
          player={el.first_name + ' ' + el.last_name} 
          position={el.position}
          team={el.team}
          ranker1={el['ranker_1_' + format]}
          ranker2={el['ranker_2_' + format]}
          ranker3={el['ranker_3_' + format]}
          ranker4={el['ranker_4_' + format]}
          ranker5={el['ranker_5_' + format]} />
      );
    });

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>Position</th>
            <th>Team</th>
            <th>Rank Avg</th>
            <th>Rank 1</th>
            <th>Rank 2</th>
            <th>Rank 3</th>
            <th>Rank 4</th>
            <th>Rank 5</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = function(state) {
  const _rankings = getRankings(state.rankings.slice(), state.selectedPosition, state.leagueFormat);
  return {
    leagueFormat: state.leagueFormat,
    rankings: _rankings,
    selectedPosition: state.selectedPosition
  };
};

export default connect(mapStateToProps)(Table);
