import React from 'react';

import { connect } from 'react-redux';

import { selectPosition } from '../actions/actions';

class SelectPosition extends React.Component {
  constructor(props) {
    super(props);
    this.positions = ['QB', 'RB', 'WR', 'TE', 'DEF/ST', 'K'];
  }

  handleChange(e) {
    e.preventDefault();

    // dispatch action
    this.props.selectPosition(e.target.value);
  }

  render() {
    const options = this.positions.map((item, i) => {
      return (
        <option key={i + '-' + item} value={item.toUpperCase()}>{item}</option>
      );
    });

    return (
      <select id="select-position" onChange={this.handleChange.bind(this)} defaultValue={this.positions[0]}>
        {options}
      </select>
    );
  }
}

const mapDispatchToProps = {
  selectPosition: selectPosition
};

export default connect(null, mapDispatchToProps)(SelectPosition);
