import React from 'react';

class TableRow extends React.Component {
  render() {
    return (
      <tr>
        <td className="table__cell--rank">{this.props.rank}</td>
        <td className="table__cell--player">{this.props.player}</td>
        <td className="table__cell--position">{this.props.position}</td>
        <td className="table__cell--team">{this.props.team}</td>
        <td className="table__cell--rank-average-raw">{this.props.rankAverageRaw}</td>
        <td className="table__cell--ranker-1">{this.props.ranker1}</td>
        <td className="table__cell--ranker-2">{this.props.ranker2}</td>
        <td className="table__cell--ranker-3">{this.props.ranker3}</td>
        <td className="table__cell--ranker-4">{this.props.ranker4}</td>
        <td className="table__cell--ranker-5">{this.props.ranker5}</td>
      </tr>
    );
  }
}

export default TableRow;
