import React from 'react';

import SelectLeagueFormat from './redux/components/SelectLeagueFormat';
import SelectPosition from './redux/components/SelectPosition';
import Table from './redux/components/Table';

class App extends React.Component {
  render() {
    return (
      <div className="site-wrapper">
        <div className="site-inner">
          <header className="section section--header">
            <h1 className="title title--site-title">Fantasy Football Rankings (React Redux)</h1>
          </header>
          <div className="section section--select">
            <div className="select-wrap select-wrap--position">
              <label htmlFor="select-position">Position</label>
              <SelectPosition />
            </div>
            <div className="select-wrap select-wrap--format">
              <label htmlFor="league-format">League Format</label>
              <SelectLeagueFormat />
            </div>
          </div>
          <div className="section section--table">
            <div className="table-wrap">
              <Table />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
