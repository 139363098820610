import { uniqueRandNumsFromRange } from './uniqueRandomNumbersFromRange';

class Rank {
  constructor(opts) {
    this.id = opts.id;
    this.first_name = 'Name';
    this.last_name = opts.id;
    this.team = 'Team ' + opts.id;
    this.position = opts.position;
    this.rank = null;
    this.rank_average = null;
    this.rank_average_raw = null;

    this.ranker_1_standard = opts.ranker_1_standard;
    this.ranker_2_standard = opts.ranker_2_standard;
    this.ranker_3_standard = opts.ranker_3_standard;
    this.ranker_4_standard = opts.ranker_4_standard;
    this.ranker_5_standard = opts.ranker_5_standard;

    this.ranker_1_ppr = opts.ranker_1_ppr;
    this.ranker_2_ppr = opts.ranker_2_ppr;
    this.ranker_3_ppr = opts.ranker_3_ppr;
    this.ranker_4_ppr = opts.ranker_4_ppr;
    this.ranker_5_ppr = opts.ranker_5_ppr;

    this.ranker_1_ppr_half = opts.ranker_1_ppr_half;
    this.ranker_2_ppr_half = opts.ranker_2_ppr_half;
    this.ranker_3_ppr_half = opts.ranker_3_ppr_half;
    this.ranker_4_ppr_half = opts.ranker_4_ppr_half;
    this.ranker_5_ppr_half = opts.ranker_5_ppr_half;
  }
}

/**
 * Generate Rankings
 *
 * @param {Object} opts rank options
 * @param {Number} amount the number of ranks to create
 * @param {Number} sets the number of sets of ranks to create
 * @return {Array} an array of rank objects
 */
function generateRankings(opts, amount, sets) {
  let rankings = [];
  let ranksStandard = uniqueRandNumsFromRange.generate(amount, sets);
  let ranksPpr = uniqueRandNumsFromRange.generate(amount, sets);
  let ranksPprHalf = uniqueRandNumsFromRange.generate(amount, sets);

  // each Set represents a different Ranker, respectively
  // we need the rank from each ranker respectively
  for (let i = 0; i < amount; i++) {
    for (let j = 0; j < sets; j++) {
      // set options
      opts.id = i;
      if (j === 0) {
        opts.ranker_1_standard = ranksStandard[j][i];
        opts.ranker_1_ppr      = ranksPpr[j][i];
        opts.ranker_1_ppr_half = ranksPprHalf[j][i];
      } else if (j === 1) {
        opts.ranker_2_standard = ranksStandard[j][i];
        opts.ranker_2_ppr      = ranksPpr[j][i];
        opts.ranker_2_ppr_half = ranksPprHalf[j][i];
      } else if (j === 2) {
        opts.ranker_3_standard = ranksStandard[j][i];
        opts.ranker_3_ppr      = ranksPpr[j][i];
        opts.ranker_3_ppr_half = ranksPprHalf[j][i];
      } else if (j === 3) {
        opts.ranker_4_standard = ranksStandard[j][i];
        opts.ranker_4_ppr      = ranksPpr[j][i];
        opts.ranker_4_ppr_half = ranksPprHalf[j][i];
      } else if (j === 4) {
        opts.ranker_5_standard = ranksStandard[j][i];
        opts.ranker_5_ppr      = ranksPpr[j][i];
        opts.ranker_5_ppr_half = ranksPprHalf[j][i];
      }
    }
    // create new Rank from total ranker data for this player
    rankings.push(new Rank(opts));
  }

  // // callback function to create and add a new Rank to the array
  // function cbEach(randNum, set, index) {
  //   // set options
  //   opts.id = index;
  //   if (set === 0) {
  //     opts.ranker_1 = randNum;  
  //   } else if (set === 1) {
  //     opts.ranker_2 = randNum;  
  //   } else if (set === 2) {
  //     opts.ranker_3 = randNum;  
  //   } else if (set === 3) {
  //     opts.ranker_4 = randNum;
  //   }

  //   // create new Rank
  //   rankings.push(new Rank(opts));
  // }
  
  return rankings;
}

function doRankings() {
  const ranksQb  = generateRankings({position: 'QB'}, 20, 5);
  const ranksRb  = generateRankings({position: 'RB'}, 20, 5);
  const ranksWr  = generateRankings({position: 'WR'}, 20, 5);
  const ranksTe  = generateRankings({position: 'TE'}, 20, 5);
  const ranksDef = generateRankings({position: 'DEF/ST'}, 20, 5);
  const ranksK   = generateRankings({position: 'K'}, 20, 5);

  return [...ranksQb, ...ranksRb, ...ranksWr, ...ranksTe, ...ranksDef, ...ranksK];
}

export default doRankings;
