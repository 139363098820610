import {
  SET_LEAGUE_FORMAT,
  SELECT_POSITION,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE
} from '../actions/actionTypes';

import doRankings from '../../rankings';

const _rankings = doRankings(); // generate ranking data
const initialState = {
  isFetching: false,
  leagueFormat: 'STANDARD',
  rankings: _rankings,
  selectedPosition: 'QB'
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LEAGUE_FORMAT: {
      const { leagueFormat } = action.payload;
      return {
        ...state,
        leagueFormat: leagueFormat
      };
    }
    case SELECT_POSITION: {
      const { selectedPosition, rankings } = action.payload;
      return {
        ...state,
        selectedPosition: selectedPosition,
        rankings: [
          ...state.rankings
        ]
      };
    }
    case FETCH_DATA_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case FETCH_DATA_SUCCESS: {
      const { rankings } = action.payload;
      return {
        ...state,
        isFetching: false,
        rankings: [
          ...state.rankings,
          ...rankings // replaces all every time
        ]
      };
    }
    case FETCH_DATA_FAILURE: {
      return {
        ...state,
        isFetching: false
      };
    }
    default:
      return state;
  }
};
