import {
  SELECT_POSITION,
  SET_LEAGUE_FORMAT,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE
} from './actionTypes';

export const setLeagueFormat = function(format) {
  return {
    type: SET_LEAGUE_FORMAT,
    payload: {
      leagueFormat: format
    }
  };
};

export const selectPosition = function(position) {
  return {
    type: SELECT_POSITION,
    payload: {
      selectedPosition: position
    }
  };
};
