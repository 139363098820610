import React from 'react';

import { connect } from 'react-redux';

import { setLeagueFormat } from '../actions/actions';

class SelectLeagueFormat extends React.Component {
  constructor(props) {
    super(props);
    this.formats = {
      standard: 'STANDARD', 
      ppr: 'PPR',
      pprHalf: 'PPR HALF'
    };
  }

  handleChange(e) {
    e.preventDefault();

    // dispatch action
    this.props.setLeagueFormat(e.target.value);
  }

  doOptions() {
    let arr = [];
    for (let format in this.formats) {
      arr.push(this.formats[format]);
    }
    return arr.map((opt,i) => {
      return (
        <option key={i + '-' + opt} value={opt}>{opt}</option>
      );
    });
  }

  render() {
    const options = this.doOptions();
    return (
      <select 
        defaultValue={this.formats.standard}
        id="league-format" 
        onChange={this.handleChange.bind(this)}>
        {options}
      </select>
    );
  }  
}

const mapDispatchToProps = {
  setLeagueFormat: setLeagueFormat
};

export default connect(null, mapDispatchToProps)(SelectLeagueFormat);
