/**
 * Get Rankings
 *
 * @param {Array} rankings the rankings data
 * @param {String} position the position to sort on
 * @param {String} leagueFormat the format of the league
 * @return {Array} the rankings data
 */
export function getRankings(rankings, position, leagueFormat) {
  return sortRankings(averageRankings(filterByPosition(rankings, position), leagueFormat));
}

/**
 * Average Rankings
 *
 * @param {Array} rankings the rankings data
 * @param {String} leagueFormat the format of the league
 * @return {Array} rankings data with averages
 */
function averageRankings(rankings, leagueFormat) {
  // get the Leauge Format
  let format = leagueFormat.replace(/ /g, '_').toLowerCase();

  // for each player, average the rankings of 1-5 and save them
  rankings.forEach((el, i) => {
    // avg the rankings
    let avgRaw = ((el['ranker_1_' + format] + el['ranker_2_' + format] + el['ranker_3_' + format] + el['ranker_4_' + format] + el['ranker_5_' + format]) / 5);
    el.rank_average_raw = avgRaw;
  });
  return rankings;
}

/**
 * Sort Rankings
 *
 * Sort rankings by raw rank average data. Assign standardized rank for display. 
 * 
 * @param {Array} rankings the rankings data
 * @return {Array} rankings sorted rankings data with display rank data
 */
function sortRankings(rankings) {
  rankings.sort((a, b) => a.rank_average_raw - b.rank_average_raw);
  // Set standardized rank based on average for display
  rankings.forEach((el, i) => el.rank = i + 1);
  return rankings;
}

/**
 * Filter Rankings
 *
 * @param {Array} rankings the rankings data
 * @param {String} position the position to sort on
 * @return {Array} filtered rankings data
 */
function filterByPosition(rankings, position) {
  return rankings.filter((el, i) => {
    return (el.position === position);
  });
}

/**
 * Sort Rankings (without array.sort)
 * 
 * @param {Array} rankings the rankings data
 * @return {Array} rankings sorted rankings data with display rank data
 */
// function sortRankingsFor(rankings) {
//   // sort the input array by the rank_average value
//   let sortedArr = [];

//   for (let sorted = 0, val = 1; sorted < rankings.length; val++) {
//     for (let j = 0; j < rankings.length; j++) {
//       if (rankings[j].rank_average === val) {
//         sortedArr.push(rankings[j]);
//         sorted++;
//       }
//     }
//   }
//   return sortedArr;
// }
